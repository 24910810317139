import { graphQLClient } from './graphql-api';
import { ProductsByIdsDocument } from '../gql/generated';

export const fetchProducts = async (ids: string[]) => {
  if (!ids.length) return [];
  const result = await graphQLClient().query({
    query: ProductsByIdsDocument,
    variables: {
      ids: ids.map(Number),
    },
  });

  return result.data.getProductsByIds;
};
