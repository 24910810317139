import React, { useEffect, useState } from 'react';
import * as S from './popover-product-offers.style';
import { Popover, Props } from 'components/ui/popover/popover';
import { observer } from 'mobx-react-lite';
import { Scrollbar } from '@components/ui/scrollbar/scrollbar';
import { OffersBySlugDocument, OffersQuery } from '../../../gql/generated';
import { Button } from '@components/ui/button/button';
import { graphQLClient } from '../../../utils/graphql-api';

type ComponentProps = {
  // offers: OffersQuery['getOffers']['items'];
  offersCount: number;
  image: string;
  name: string;
  slug: string;
};

export const PopoverProductOffers = observer((props: ComponentProps & Omit<Props, 'children'>) => {
  const [offers, setOffers] = useState([]);
  const [page, setPage] = useState(1);

  const nextPage = () => setPage(page + 1);

  const onMouseOver = async () => {
    if (offers.length === 0) {
      const { data } = await graphQLClient().query({
        query: OffersBySlugDocument,
        variables: {
          productSlug: props.slug,
          take: 10,
        },
      });

      setOffers(data.getOffersByProductSlug.items);
    }
  };

  useEffect(() => {
    if (offers.length === 0) return;
    graphQLClient()
      .query({
        query: OffersBySlugDocument,
        variables: {
          productSlug: props.slug,
          take: 10 * page,
        },
      })
      .then(({ data }) => setOffers(data.getOffersByProductSlug.items));
  }, [page]);

  const offersLeft = props.offersCount - page * 10;

  return (
    <S.StyledPopoverProductOffers onMouseOver={onMouseOver}>
      <Popover zIndex={props.zIndex ?? 4} target={props.target} align={props.align ?? 'center'}>
        <Scrollbar>
          <S.Products>
            {offers.map((offer) => (
              <S.Product key={offer.id}>
                <S.ProductTitle>
                  {offer.sellerName[0].toUpperCase()}
                  {offer.sellerName.slice(1)}
                </S.ProductTitle>
                <S.ProductPrice>{offer.price.toLocaleString('ru-RU')} ₽</S.ProductPrice>
              </S.Product>
            ))}

            {offersLeft > 0 && (
              <S.ShowMore>
                <Button onClick={nextPage}>
                  Показать ещё ({offersLeft < 10 ? offersLeft : 10})
                </Button>
              </S.ShowMore>
            )}
          </S.Products>
        </Scrollbar>
      </Popover>
    </S.StyledPopoverProductOffers>
  );
});
