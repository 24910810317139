import Head from 'next/head';
import { useStore } from '@stores/root';
import { observer } from 'mobx-react-lite';
import React, { CSSProperties, PropsWithChildren, useEffect } from 'react';
import { mobileEffect } from 'utils/mobile';
import { useRouter } from 'next/router';
import { Tooltip } from '@components/ui/tooltip/tooltip';
import { NoSSR } from '@components/NoSSR';
import { Header } from '@components/layout/header/header';
import { Footer } from './footer/footer';
import { HowItWorks } from '@components/blocks/how-it-works/how-it-works';
import { PageContainer, Wrapper } from '@components/ui/wrapper/wrapper.style';
import { Portal } from '../../utils/Portal';
import { AnimatePresence } from 'framer-motion';
import classNames from 'classnames';
import { BannerSlider } from '@components/layout/header/blocks/banner-slider/banner-slider';
import * as S from '@components/layout/header/header.style';
import { TapBar } from '@components/layout/header/blocks/tap-bar/tap-bar';
import Script from 'next/script';
import { waitAsync } from '../../utils/wait';
import { RelinkItems } from '@components/layout/elements/relink-items/relink-items';
import { WH } from '@components/WH';
import { CpaBanner } from '@components/CpaBanner';

type LayoutProps = {
  style?: CSSProperties;
  footer?: {
    type: string;
    data: any;
  };
  meta?: { title?: string; description?: string; image?: string };
  showHeaderBanner?: boolean;
  showHowItWorks?: boolean;
  noFooterMargin?: boolean;
  noHeaderGreyZone?: boolean;
  currentCategory?: string;
  aboutBlockText?: string;
  hidePreFooterBlock?: boolean;
  lessHowItWorksMargin?: boolean;
  updatedAt?: Date;
};

const Layout: FCC<PropsWithChildren<LayoutProps>> = ({
  style,
  meta,
  footer,
  showHeaderBanner,
  showHowItWorks = true,
  noFooterMargin,
  noHeaderGreyZone,
  children,
  currentCategory,
  aboutBlockText,
  hidePreFooterBlock,
  lessHowItWorksMargin,
  updatedAt,
}) => {
  const { ui, modal, project: projectStore, geo } = useStore();

  mobileEffect();

  // const hideBalloons = (e) => {
  //   const balloonClicked = ((target) => {
  //     while (target) {
  //       if (typeof target.classList !== 'undefined' && target.classList.contains('balloon')) {
  //         return true;
  //       }
  //       target = target.parentNode;
  //     }
  //
  //     return false;
  //   })(e.target);
  //
  //   if (!balloonClicked) {
  //     ui.closeBalloon();
  //   }
  // };

  const router = useRouter();

  const handleRouteChange = async (url) => {
    if (projectStore.googleAnalytics) {
      while (!window.gtag) {
        console.log('Gtag loading');
        await waitAsync(100);
      }
      window.gtag('config', projectStore.googleAnalytics, {
        page_path: url,
      });
    }
    if (projectStore.yandexMetrika) {
      while (!window.ym) {
        await waitAsync(100);
      }
      window.ym(projectStore.yandexMetrika, 'hit', url);
    }
    if (projectStore.mailTop) {
      while (!window._tmr) {
        await waitAsync(100);
      }
      window._tmr.push({
        id: projectStore.mailTop,
        type: 'pageView',
        start: new Date().getTime(),
      });
    }
  };

  useEffect(() => {
    ui.onResize();
  }, []);

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    // if (!geo.without && geo.slug === null) {
    //   modal.show('confirmGeo', {
    //     title: 'Выберите ваш город',
    //     acceptButtonText: 'Выбрать',
    //     declineButtonText: 'Продолжить без выбора',
    //     onAccept: () => modal.show('geo'),
    //     onDecline: () => geo.setWithout(true),
    //   });
    // }

    if (document.referrer.includes('google')) {
      return;
    }

    const confirmGeo = localStorage.getItem('confirmGeo');

    if (confirmGeo === null) {
      const curGeoName = document.querySelector('meta[name=geo]').getAttribute('content');

      modal.show('confirmGeo', {
        title: `Ваш город определился как ${curGeoName}`,
        acceptButtonText: 'Верно',
        declineButtonText: 'Выбрать город',
        onAccept: () => {
          localStorage.setItem('confirmGeo', '1');
        },
        onDecline: () => modal.show('geo'),
      });
    }
  }, []);

  const defaultMeta = {
    title: `${projectStore.name} ${geo.cityName} — специализированный маркетплейс`,
    description: `Вся продукция ${projectStore.name} на специализированном маркетплейсе по низким ценам! Характеристики, фото, максимальный ассортимент. Оперативная доставка ${geo.cityName}!`,
    image: projectStore.favicon
      ? `https://${projectStore.domain}/${projectStore.favicon.path}`
      : '',
  };

  return (
    <>
      {projectStore?.googleAnalytics ? (
        <>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${projectStore.googleAnalytics}`}
          />
          <script
            id="google_tag_manager"
            dangerouslySetInnerHTML={{
              __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${projectStore.googleAnalytics}', { page_path: window.location.pathname });
            `,
            }}
          />
        </>
      ) : null}
       <>
          <script
            id="yandex_metrics"
            dangerouslySetInnerHTML={{
              __html: `
                 (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
                 m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
                 (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
              
                 ym(95767516, "init", {
                      clickmap:true,
                      trackLinks:true,
                      accurateTrackBounce:true,
                      webvisor:true,
                      ecommerce:"dataLayer"
                 });
            `,
            }}
          />
        </>
      {projectStore?.mailTop ? (
        <>
          <script
            id="mail_top"
            dangerouslySetInnerHTML={{
              __html: `
                  var _tmr = window._tmr || (window._tmr = []);
                  _tmr.push({id: "${projectStore.mailTop}", type: "pageView", start: (new Date()).getTime()});
                  (function (d, w, id) {
                    if (d.getElementById(id)) return;
                    var ts = d.createElement("script"); ts.type = "text/javascript"; ts.async = true; ts.id = id;
                    ts.src = "https://top-fwz1.mail.ru/js/code.js";
                    var f = function () {var s = d.getElementsByTagName("script")[0]; s.parentNode.insertBefore(ts, s);};
                    if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); }
                  })(document, window, "topmailru-code");
            `,
            }}
          />
        </>
      ) : null}

      {/* Поделиться от Яндекс */}
      <Script src="https://yastatic.net/share2/share.js" strategy="lazyOnload" />

      <WH>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5" />
          <title>{meta?.title ?? defaultMeta.title}</title>
          {projectStore.favicon && <link rel="icon" href={projectStore.favicon.path} />}

          <meta name="description" content={meta?.description ?? defaultMeta.description} />

          <meta property="og:title" content={meta?.title ?? defaultMeta.title} />
          <meta property="og:description" content={meta?.description ?? defaultMeta.description} />
          <meta property="og:image" content={meta?.image ?? defaultMeta.image} />
          <meta property="og:type" content="website" />

          {projectStore?.yandexVerification ? (
            <meta name="yandex-verification" content={projectStore.yandexVerification} />
          ) : null}
          {projectStore?.googleVerification ? (
            <meta name="google-site-verification" content={projectStore.googleVerification} />
          ) : null}
        </Head>
      </WH>

      {projectStore?.yandexMetrika ? (
        <noscript>
          <div>
            <img
              src={`https://mc.yandex.ru/watch/${projectStore.yandexMetrika}`}
              style={{ position: 'absolute', left: '-9999px;' }}
              alt=""
            />
          </div>
          <div>
            <img
              src={`https://top-fwz1.mail.ru/counter?id=${projectStore.mailTop};js=na`}
              style={{ border: 0, position: 'absolute', left: '-9999px' }}
              alt="Top.Mail.Ru"
            />
          </div>
        </noscript>
      ) : null}
      <main
        className="relative h-screen flex flex-col flex-grow"
        style={{
          minHeight: '-webkit-fill-available',
          WebkitOverflowScrolling: 'touch',
          ...style,
        }}
      // onClick={hideBalloons}
      >
        <div itemScope itemType="https://schema.org/WebPage">
          <WH>
            <span className="hidden">
              <span itemProp="name">{meta?.title ?? projectStore?.title}</span>
              <span itemProp="description">{meta?.description}</span>
            </span>
          </WH>

          <Header
            projectName={projectStore?.name}
            postfix={projectStore?.postfix}
            categories={projectStore.categories}
          />

          <PageContainer className={classNames({ fixedContainer: ui.header.fixed })}>
            {!noHeaderGreyZone && (
              <S.HeaderBottomGreyZone
                className={router.pathname === '/info/guide' && 'guide'}
                isHidden={router.pathname.trim() !== '/'}
              >
                {showHeaderBanner && <BannerSlider />}
              </S.HeaderBottomGreyZone>
            )}

            {/* {showHeaderBanner && <BannerSlider />} */}

            {showHowItWorks && <HowItWorks lessMargin={lessHowItWorksMargin} />}
            {/* <CpaBanner style={{
              marginTop: '24px',
              marginBottom: '24px',
            }}/> */}

            {children}
          </PageContainer>

          <TapBar />

          <Footer
            hideAboutBlock={hidePreFooterBlock}
            aboutBlockText={aboutBlockText}
            project={projectStore}
            text={footer}
            noMargin={noFooterMargin}
            projectName={projectStore.name}
            postfix={projectStore.postfix}
            categories={projectStore.categories}
            currentCategory={currentCategory}
            updatedAt={updatedAt}
          />
        </div>
        <NoSSR>
          <Tooltip />
        </NoSSR>
      </main>

      <Portal blockID="modal-root">
        <AnimatePresence>{modal.components.map((component) => component)}</AnimatePresence>
      </Portal>
    </>
  );
};

export default observer(Layout);
