import styled from 'styled-components';
import { device, theme } from '@components/ui/theme';

export const StyledPopoverProductOffers = styled.div`
  margin-right: 10px;
  @media screen and (max-width: 992px) {
    .bottom {
      right: 0;

      &:after {
        right: 24px;
        left: unset;
      }
    }
  }

  @media (max-width: 706px) {
    flex-basis: auto;

    .bottom {
      right: unset;
      left: 0;

      &:after {
        right: unset;
        left: 24px;
      }
    }
  }
`;

export const Products = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;
  margin-right: 10px;
  box-sizing: border-box;
  overflow-y: auto;
  max-height: 383px;
  width: 359px;

  @media ${device.mobileM} {
    margin: 10px;
  }
`;

export const ShowMore = styled.div`
  margin-top: 20px;
  padding-right: 15px;
  padding-left: 20px;

  button {
    height: 42px;
  }
`;

export const Product = styled.span`
  padding: 20px 0 15px 20px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-of-type) {
    border-bottom: 1px solid #efedf0;
  }

  &:last-of-type {
    padding-bottom: 0;
  }

  &:first-child {
    padding-top: 0;
  }
`;

export const Price = styled.span`
  display: inline-grid;
  grid-auto-flow: column;
  grid-column-gap: 6px;
  justify-content: flex-start;

  color: ${theme.colors.black};
  font-size: 12px;
  line-height: 14px;
  font-weight: bold;
  letter-spacing: 0.03em;

  &:after {
    content: attr(data-old-price);

    color: ${theme.colors.greyPrimary};
    font-size: 11px;
    line-height: 15px;
    font-weight: normal;
    text-decoration: line-through;
    letter-spacing: initial;
  }
`;

export const Footer = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: flex-end;
  box-sizing: border-box;
  padding: 10px;
  border-top: 1px solid #d9dde7;
`;

export const TotalPrice = styled(Price)`
  padding-left: 10px;

  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-row-gap: 2px;
  align-items: flex-end;
  grid-column: 1;
  grid-row: 2;

  font-size: 18px;
  line-height: 22px;

  &:before {
    content: 'Итого:';

    grid-column: span 2;
    grid-row: 1;

    color: ${theme.colors.greyPrimary};
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: initial;
  }

  &:after {
    padding-bottom: 2px;
    grid-column: 2;
    grid-row: 2;
  }
`;

export const ProductLeft = styled.div``;

export const ProductRight = styled.div`
  margin-left: 10px;
`;

export const ProductImage = styled.div`
  width: 70px;
  height: 52px;
  position: relative;
`;

export const ProductPrice = styled.div`
  font-size: 15px;
  line-height: 140%;
  color: ${theme.colors.black};
`;

export const ProductTitle = styled.div`
  margin-right: 40px;
  font-size: 15px;
  line-height: 140%;
  color: ${theme.colors.black};
`;
