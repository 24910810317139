import { IncomingHttpHeaders } from 'http';
import { ApolloClient, DefaultOptions, InMemoryCache } from '@apollo/client';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

// const errorLink = (headers?: IncomingHttpHeaders) =>
//   onError(({ graphQLErrors, networkError, operation, forward }) => {
//     const isBrowser = process.browser;
//     const domain = isBrowser ? window.location.hostname : headers.host;
//     const location = isBrowser ? window.location.pathname : headers.location;
//
//     if (networkError) console.log(`[Apollo error]: Domain: ${domain}, URL: ${location}`);
//
//     forward(operation);
//   });

const apolloClients = new Map<string, ApolloClient<object>>();

export const graphQLClient = (headers?: IncomingHttpHeaders): ApolloClient<object> => {
  const graphQLURI = getGraphQLURI(headers);
  const isBrowser = process.browser;
  const host = isBrowser ? window.location.hostname : headers.host;

  const client = apolloClients.get(host);

  if (client) {
    return client;
  }

  const clienHeaders = {
    'x-auth-token':  '1KANT216G8RwXtL4UrROJPyv5tphhyLuTjHwzw2qXkhAnsTi9tN3dHrzsZ8EQu3HvxuwhXM3nRsnSIXtBczlH8vRhTyY6J8CoxbJmcIAq0wFM1MQQHSSqOfvPNC7Ps1e'
  };

  if(!isBrowser) {
    clienHeaders['Host'] = headers.host;
  }

  const newClient = new ApolloClient({
    uri: graphQLURI,
    cache: new InMemoryCache(),
    defaultOptions,
    ssrMode: typeof window === 'undefined',
    headers: clienHeaders
  });

  apolloClients.set(host, newClient);

  return newClient;
};

const getGraphQLURI = (headers?: IncomingHttpHeaders) => {
  const isBrowser = process.browser;

  if (!isBrowser) {
    return 'http://market-service:8181/graphql';
  }

  const host = isBrowser ? window.location.hostname : headers.host;
  const schema = isBrowser
    ? window.location.protocol.slice(0, -1)
    : headers['x-forwarded-proto'] ?? 'http';

  return schema + '://' + host + '/backend/graphql';
};

export const queryToProductsPriceFilter = (query) => {
  // let price = '{';
  //
  // if (query.priceFrom) {
  //   price += `from:${query.priceFrom}`;
  // }
  // if (query.priceFrom && query.priceTo) {
  //   price += ',';
  // }
  // if (query.priceTo) {
  //   price += `to:${query.priceTo}`;
  // }
  // price += '}';

  return {
    from: Number(query.priceFrom) || 0,
    to: Number(query.priceTo) || 0,
  };
};
